import React, { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../../Styles/navbar.css";
import mpnrcLogo from '../../Assets/mpnrc-logo.png'
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function Navbar() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="header">
      <div className='logo'>
        <img src={mpnrcLogo} alt='' onClick={()=>navigate("/")} style={{cursor:'pointer'}}/>
        <div className="logo-name">
          <h4>MP Nurses Registration Council</h4>
          <h5>GOVT OF MADHYA PRADESH</h5>
        </div>
      </div>
      <nav ref={navRef}>
      {isSmallScreen ? (
        <>
          <Link to="/" className={isActive("/") ? "active" : ""}>Home</Link>
          <Link to="/aboutus" className={isActive("/aboutus") ? "active" : ""}>About Us</Link>
          <Link to="/termsconditions" className={isActive("/termsconditions") ? "active" : ""}>Terms and Conditions</Link>
          <Link to="/privacypolicy" className={isActive("/privacypolicy") ? "active" : ""}>Privacy Policy</Link>
          <Link to="/refund" className={isActive("/refund") ? "active" : ""}>Refund & Cancellation</Link>
          <Link to="/contactus" className={isActive("/contactus") ? "active" : ""}>Contact Us</Link>
        </>
      ) : (
        <>
          <Link to="/" className={isActive("/") ? "active" : ""}>Home</Link>
          <Link to="/aboutus" className={isActive("/aboutus") ? "active" : ""}>About Us</Link>
          <Link to="/termsconditions" className={isActive("/termsconditions") ? "active" : ""}>Terms and Conditions</Link>
          <Link to="/privacypolicy" className={isActive("/privacypolicy") ? "active" : ""}>Privacy Policy</Link>
          <Link to="/refund" className={isActive("/refund") ? "active" : ""}>Refund & Cancellation</Link>
          <div className="navbar-button" onClick={() => navigate("/contactus")}>Contact Us</div>
        </>
      )}
       <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <div className=" nav-logo-btn"><img src={mpnrcLogo} alt='' onClick={()=>navigate("/")} style={{cursor:'pointer'}}/>
        <div className="logo-name">
          <h4>MP Nurses Registration Council</h4>
          <h5>GOVT OF MADHYA PRADESH</h5>
        </div></div>
       <button
          className="nav-btn nav-close-btn"
          onClick={showNavbar}>
          <FaTimes />
        </button>
       </div>
        
      </nav>

      <button
        className="nav-btn"
        onClick={showNavbar}>
        <FaBars />
      </button>
    </div>
  );
}

export default Navbar;

