import React from 'react';
import '../../Styles/home.css';
import Banner from '../../Assets/nursing-students.jpg'
const HomePage = () => {
    return (
        <>
            <div className='banner'>
                <img src={Banner} alt='' />
                <div className="overlay">
                    <h2 className="hero-title"> ANNOUNCEMENTS</h2>
                    <marquee behavior="scroll" direction="up" scrolldelay="110" style={{ height: '150px' }}>

                        {/* No new announcements. */}
                        <p className='herocontent'>
                            APPLY ONLINE EXAM FORM FOR GNM & ANN 1ST YEAR <span className="label">New</span>
                        </p>
                        {/* <p className='herocontent'>
                            Exam form registration is now open. For more details, please see the latest information below or contact the administration office. <span className="label">New</span>
                        </p> */}
                    </marquee>
                </div>
            </div>
            <div className="content">
                <div className="table-container">
                    <h2 className="section-title">Latest Information</h2>
                    <table className="info-table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>APPLY ONLINE EXAM FORM FOR GNM & ANN 1ST YEAR.</td>
                                <td>17-DEC-2024</td>
                                <td>-</td>
                                <td><a href="https://exam.mpnrconline.org/login" target="_blank" rel="noopener noreferrer">Click Here</a></td>
                            </tr>
                            {/* <tr>
                                <td>APPLY ONLINE EXAM FORM FOR GNM 3RD YEAR</td>
                                <td>05-NOV-2024</td>
                                <td>-</td>
                                <td><a href="https://exam.mpnrconline.org/login" target="_blank" rel="noopener noreferrer">Click Here</a></td>
                            </tr>
                            <tr>
                                <td>APPLY ONLINE EXAM FORM FOR ANM 2ND YEAR</td>
                                <td>05-NOV-2024</td>
                                <td>-</td>
                                <td><a href="https://exam.mpnrconline.org/login" target="_blank" rel="noopener noreferrer">Click Here</a></td>
                            </tr>
                            <tr>
                                <td>APPLY ONLINE EXAM FORM FOR GNM 2ND YEAR</td>
                                <td>05-NOV-2024</td>
                                <td>-</td>
                                <td><a href="https://exam.mpnrconline.org/login" target="_blank" rel="noopener noreferrer">Click Here</a></td>
                            </tr>
                            <tr>
                                <td>Online student enrolment for year 2022-23 - ANM & GNM</td>
                                <td>26-OCT-2024</td>
                                <td>-</td>
                                <td><a href="https://exam.mpnrconline.org/instituteLogin" target="_blank" rel="noopener noreferrer">Click Here</a></td>
                            </tr> */}
                            {/* <tr>
                                <td>Link for exam form forwarding (Login with your old username and passwords). </td>
                                <td>26-JUL-2024</td>
                                <td>-</td>
                                <td><a href="https://exam.mpnrconline.org/instituteLogin" target="_blank" rel="noopener noreferrer">Click Here</a></td>
                            </tr> */}
                            {/* <tr>
                                <td>GUIDELINE FOR FORM FORWARDING IN HINDI ( PLEASE READ TO FORWARD EXAM FORM). </td>
                                <td>26-JUL-2024</td>
                                <td>-</td>
                                <td><a href="https://gail-markers-bucket.s3.ap-south-1.amazonaws.com/FORM_FORWARD_GUID.pdf" target="_blank" rel="noopener noreferrer">Click Here</a></td>
                            </tr>  */}
                        </tbody>
                    </table>
                </div>
                <div className="login-container">
                    <h2 className="section-title">Logins</h2>
                    <div className='buttons'>
                        <div className='exam-form'>
                            <h3>For Enrolment - Year 2022-23</h3>
                            {/* <div className='line-separate'></div> */}
                            <a href="https://exam.mpnrconline.org/login" className='home-buttons' target="_blank" rel="noopener noreferrer">Apply Exam Form</a>
                            {/* <a className='home-buttons disabled-link' onClick={(e) => e.preventDefault()}>Apply Exam Form</a> */}
                            <a href="https://exam.mpnrconline.org/instituteLogin" className='home-buttons' target="_blank" rel="noopener noreferrer">College Login</a>
                            {/* <a href="https://exam.mpnrconline.org/instituteLogin" className='home-buttons' target="_blank" rel="noopener noreferrer">College Login</a> */}
                        </div>

                        {/* <div className='enrolment'> */}
                        {/* <h3>For Enrolment</h3> */}
                        {/* <div className='line-separate'></div> */}
                        {/* <a href="https://examreg.mpnrconline.org/Admin/?Type=College" className='home-buttons' target="_blank" rel="noopener noreferrer">College Login</a> */}
                        {/* <a className='home-buttons disabled-link' onClick={(e) => e.preventDefault()}>College Login</a> */}
                        {/* <a href="https://examreg.mpnrconline.org/Admin/?Type=Counsellor" className='home-buttons' target="_blank" rel="noopener noreferrer">Counsellor login</a> */}
                        {/* <a className='home-buttons disabled-link' onClick={(e) => e.preventDefault()}>Counsellor login</a> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HomePage;



